<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="序号" required prop="serialNumber">
            <el-input  v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分细项" required prop="detailName">
            <el-input v-model="form.detailName"></el-input>
        </el-form-item>
        <el-form-item label="细项基准分" required prop="trueScore">
            <el-input  v-model="form.detailTrueScore"  ></el-input>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AdddetailDialog',
  data: function () {
    return {
      rules: {
        serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur' }],
        detailName: [{ required: true, message: '请输入评分细项', trigger: 'blur' }],
        trueScore: [{ required: true, message: '请输入细项基准分', trigger: 'blur' }]
      },
      form: { }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
.form{
    :deep(.el-input__inner){
        width: 80%;
    }
}
.mainform .form .bascform[data-v-40fd2650] .el-form-item{
  margin-bottom: 20px;
}
</style>
